export default {
  format: {
    date: 'd.MM.yyyy',
    time: 'p',
    dateTime: '$t(format:date) $t(format:time)',
  },

  translation: {
    common: {
      about: 'About 4ga Boards',
      aboutShort: 'About',
      account: 'Account',
      actions: 'Comments',
      addAttachment: 'Add attachment',
      addAttachmentButton: 'Add Attachment',
      addAttachmentExtra: '(Drop here, Ctrl+V or click to upload)',
      addBoard: 'Add Board',
      addCard: 'Add Card',
      addComment: 'Add comment',
      addDescription: 'Add Description',
      addDueDate: 'Add Due Date',
      addLabel: 'Add Label',
      addList: 'Add List',
      addManager_title: 'Add Manager',
      addMember_title: 'Add Member',
      addMember: 'Add Member',
      addMembers: 'Add Members',
      addProject: 'Add Project',
      addTask: 'Add Task',
      addUser_title: 'Add User',
      administrator: 'Administrator',
      all: 'All',
      allChangesWillBeAutomaticallySavedAfterConnectionRestored: 'All changes will be automatically saved<br />after connection restored',
      areYouSureYouWantToDeleteThisAttachment: 'Are you sure you want to delete this attachment?',
      areYouSureYouWantToDeleteThisBoard: 'Are you sure you want to delete this board?',
      areYouSureYouWantToDeleteThisCard: 'Are you sure you want to delete this card?',
      areYouSureYouWantToDeleteThisComment: 'Are you sure you want to delete this comment?',
      areYouSureYouWantToDeleteThisLabel: 'Are you sure you want to delete this label?',
      areYouSureYouWantToDeleteThisList: 'Are you sure you want to delete this list?',
      areYouSureYouWantToDeleteThisProject: 'Are you sure you want to delete this project?',
      areYouSureYouWantToDeleteThisTask: 'Are you sure you want to delete this task?',
      areYouSureYouWantToDeleteThisUser: 'Are you sure you want to delete this user?',
      areYouSureYouWantToLeaveBoard: 'Are you sure you want to leave the board?',
      areYouSureYouWantToLeaveProject: 'Are you sure you want to leave the project?',
      areYouSureYouWantToRemoveThisManagerFromProject: 'Are you sure you want to remove this manager from the project?',
      areYouSureYouWantToRemoveThisMemberFromBoard: 'Are you sure you want to remove this member from the board?',
      attachment: 'Attachment',
      attachments: 'Attachments',
      authentication: 'Authentication',
      avatar: 'Avatar',
      avatarText: 'Profile picture',
      back: 'Back',
      background: 'Background',
      backToDashboard: 'Back to Dashboard',
      backToProject: 'Back to Project',
      board_title: 'Board',
      board: 'board',
      boardNotFound_title: 'Board Not Found',
      boards_one: '{{count}} board',
      boards_other: '{{count}} boards',
      boards_title_one: '{{count}} Board',
      boards_title_other: '{{count}} Boards',
      boards_title: 'Boards',
      boards: 'boards',
      canComment: 'Can comment',
      canEditContentOfBoard: 'Can edit the content of the board',
      cannotEditInstanceSettings: 'You are not authorized to edit instance settings!',
      cannotEditUsersSettings: 'You are not authorized to edit instance users!',
      canOnlyViewBoard: 'Can only view the board',
      card_title: 'Card',
      card: 'card',
      cardActions_title: 'Card Actions',
      cardNotFound_title: 'Card Not Found',
      cardOrActionAreDeleted: 'Card or action are deleted',
      cards_one: '{{count}} card',
      cards_other: '{{count}} cards',
      cards_title_one: '{{count}} Card',
      cards_title_other: '{{count}} Cards',
      cards_title: 'Cards',
      cards: 'cards',
      clearFilter: 'Clear filter',
      close: 'Close',
      closeCard: 'Close Card',
      closeDropdown: 'Close dropdown',
      collapseList: 'Collapse List',
      color: 'Color',
      connectedToGithub: 'Connected to Github',
      connections: 'Edit Github Connection',
      connectToGithub: 'Connect to Github',
      createLabel_title: 'Create Label',
      createTextFile_title: 'Create Text File',
      currentPassword: 'Current password',
      currentValue: 'Current Value',
      dangerZone_title: 'Danger Zone',
      dashboard: 'Dashboard',
      date: 'Date',
      deleteAttachment_title: 'Delete Attachment',
      deleteBoard_title: 'Delete Board',
      deleteCard_title: 'Delete Card',
      deleteComment_title: 'Delete Comment',
      deleteComment: 'Delete Comment',
      deleteLabel_title: 'Delete Label',
      deleteList_title: 'Delete List',
      deleteProject_title: 'Delete Project',
      deleteTask_title: 'Delete Task',
      deleteUser_title: 'Delete User',
      demoMode: 'Demo Mode',
      demoModeExplanation: 'Demo Mode - some features affecting other users are disabled! Every new user receives admin privileges.',
      description: 'Description',
      descriptionCompactSidebar: 'This option makes the sidebar narrower.',
      descriptionLocalRegistration: 'Enable or disable local user registration. This registration method allows users to create an account with a username and password.',
      descriptionSLanguage: 'Select the language you want to use in 4ga Boards.',
      descriptionSsoRegistration: 'Enable or disable SSO user registration. This registration method allows users to create an account using an external account e.g. Google Account.',
      descriptionSubscribeToMyOwnCards: 'Automatically subscribe to cards that I create.',
      descriptionUserRegistration: 'Enable or disable all user registration methods. If you disable all registration methods, only administrators will be able to create new users.',
      detectAutomatically: 'Detect automatically',
      disabled: 'Disabled',
      docs: 'Documentation',
      done: 'Done',
      dropFileToUpload: 'Drop files to upload',
      dueDate_title: 'Due Date',
      dueDateSummary: 'The closest due date for unfinished task:',
      duplicateTask: 'Duplicate Task',
      editAttachment_title: 'Edit Attachment',
      editAttachment: 'Edit Attachment',
      editAttachmentName: 'Edit Attachment Name',
      editAvatar_title: 'Edit Avatar',
      editBoard_title: 'Edit Board',
      editCard: 'Edit Card',
      editComment: 'Edit Comment',
      editDescription: 'Edit Description',
      editDueDate_title: 'Edit Due Date',
      editDueDate: 'Edit Due Date',
      edited: 'Edited',
      editEmail_title: 'Edit Email',
      editInformation_title: 'Edit Information',
      editLabel_title: 'Edit Label',
      editLabel: 'Edit Label',
      editLabels: 'Edit Labels',
      editList: 'Edit List',
      editMembers: 'Edit Members',
      editor: 'Editor',
      editPassword_title: 'Edit Password',
      editPermissions_title: 'Edit Permissions',
      editProject: 'Edit Project',
      editTask: 'Edit Task',
      editTimer_title: 'Edit Timer',
      editTimer: 'Edit Timer',
      editUser: 'Edit User',
      editUsername_title: 'Edit Username',
      email: 'Email',
      emailAlreadyInUse: 'Email already in use',
      empty: 'Empty',
      enabled: 'Enabled',
      enableLocalRegistration: 'Local User Registration',
      enableRegistration: 'User Registration',
      enableSsoRegistration: 'SSO User Registration',
      enterAttachmentName: 'Enter attachment name...',
      enterBoardName: 'Enter board name...',
      enterCardName: 'Enter card name...',
      enterCardNameWithHint: 'Enter card name... [Ctrl+Enter] - open',
      enterComment: 'Enter comment...',
      enterDescription: 'Enter description...',
      enterDueDate: 'Enter due date...',
      enterFileName: 'Enter file name...',
      enterGithubRepository: 'Enter Github repository... <Owner/Repo>',
      enterLabelName: 'Enter label name...',
      enterListName: 'Enter list name...',
      enterProjectName: 'Enter project name...',
      enterTaskDescription: 'Enter task description...',
      expandList: 'Expand List',
      exportBoard_title: 'Export Board',
      exportBoardExplanation: 'Your download will start automatically, when the export is ready.',
      fetching: 'Fetching...',
      filterBoards: 'Filter boards...',
      filterByLabels_title: 'Filter By Labels',
      filterByMembers_title: 'Filter By Members',
      filterProjects: 'Filter projects...',
      from4gaBoards: 'From 4ga Boards',
      fromTrello: 'From Trello',
      general: 'General',
      github: 'Github',
      hideBoards: 'Hide boards',
      hideSidebar: 'Hide Sidebar',
      hideTasks: 'Hide Tasks',
      hours: 'Hours',
      ideas: 'Open',
      importBoard_title: 'Import Board',
      importGettingStartedProject: 'Import Getting Started Project',
      importNonExistingUsers: 'Create accounts for non-existing users',
      importProjectManagers: 'Add project managers',
      inProgress: 'In Progress',
      instanceSettings: 'Instance Settings',
      insufficientPermissions: 'Insufficient permissions',
      invalidCurrentPassword: 'Invalid current password',
      kanban: 'Kanban',
      labels: 'Labels',
      language: 'Language',
      lastLogin: 'Last login',
      latestVersion: 'Latest version:',
      leaveBoard_title: 'Leave Board',
      leaveProject_title: 'Leave Project',
      linkCard: 'Copy Link',
      list: 'List',
      listActions_title: 'List Actions',
      managers: 'Managers',
      members: 'Members',
      minutes: 'Minutes',
      modifySettings: 'Modify Settings',
      moveCard_title: 'Move Card',
      moveCardToList: 'Move Card To List',
      name: 'Name',
      newEmail: 'New e-mail',
      newPassword: 'New password',
      newUsername: 'New username',
      noBoards: 'No boards',
      noConnectionToServer: 'No connection to server',
      noLists: 'No lists',
      noProjects: 'No projects',
      notConnectedToGithub: 'Not connected to Github',
      notifications: 'Notifications',
      noUnreadNotifications: 'No unread notifications',
      ofBoards_one: 'of {{count}} board',
      ofBoards_other: 'of {{count}} boards',
      ofCards_one: 'of {{count}} card',
      ofCards_other: 'of {{count}} cards',
      ofProjects_one: 'of {{count}} project',
      ofProjects_other: 'of {{count}} projects',
      openDropdown: 'Open dropdown',
      optional_inline: 'optional',
      organization: 'Organization',
      phone: 'Phone',
      preferences: 'Preferences',
      profile: 'Profile',
      profileAndSettings: 'Profile and Settings',
      project_title: 'Project',
      project: 'project',
      projectNotFound_title: 'Project Not Found',
      projects_one: '{{count}} project',
      projects_other: '{{count}} projects',
      projects_title_one: '{{count}} Project',
      projects_title_other: '{{count}} Projects',
      projects_title: 'Projects',
      projects: 'projects',
      projectSettings: 'Project Settings',
      removeManager_title: 'Remove Manager',
      removeMember_title: 'Remove Member',
      renameBoard: 'Rename Board',
      renameProject: 'Rename Project',
      reorderBoards: 'Reorder boards within the project',
      requiresAdminRights: '(requires admin rights)',
      searchLabels: 'Search labels...',
      searchMembers: 'Search members...',
      searchUsers: 'Search users...',
      seconds: 'Seconds',
      selectBoard: 'Select board',
      selectedProject: 'Selected Project',
      selectList: 'Select list',
      selectPermissions_title: 'Select Permissions',
      selectProject: 'Select project',
      settings: 'Settings',
      settingsAbout: 'Settings: About',
      settingsAccount: 'Settings: Account',
      settingsAuthentication: 'Settings: Authentication',
      settingsInstance: 'Settings: Instance',
      settingsPreferences: 'Settings: Preferences',
      settingsProfile: 'Settings: Profile',
      settingsProject: 'Settings: Project',
      settingsUsers: 'Settings: Users',
      showBoards: 'Show boards',
      showing: 'Showing',
      showSidebar: 'Show Sidebar',
      showTasks: 'Show Tasks',
      sidebarCompact: 'Compact Sidebar',
      simple: 'Simple',
      ssoGoogleEmail: 'Google Email',
      startTimer: 'Start Timer',
      stopTimer: 'Stop Timer',
      subscribed: 'Subscribed',
      subscribeToMyOwnCards: 'Subscribe to my own cards',
      taskActions_title: 'Task Actions',
      tasks: 'Tasks',
      template: 'Template',
      thereIsNoPreviewAvailableForThisAttachment: 'There is no preview available for this attachment',
      time: 'Time',
      timer: 'Timer',
      todo: 'Todo',
      toggleAdmin: 'Toggle Admin',
      toggleAttachments: 'Toggle Attachments',
      toggleComments: 'Toggle Comments',
      toggleDescription: 'Toggle Description',
      toggleFilter: 'Toggle filter: boards/projects [Tab]',
      toggleSettings: 'Toggle Settings',
      toggleTasks: 'Toggle Tasks',
      toTest: 'To Test',
      unableToFetch: 'Unable to fetch',
      unsavedChanges: 'Local changes',
      userActions_title: 'User Actions',
      userAddedThisCardToList: '<0>{{user}}</0><1> added this card to {{list}}</1>',
      userAvatar: 'User Avatar',
      userDuplicatedThisCard: '{{user}} duplicated this card',
      userLeftNewCommentToCard: '{{user}} left a new comment «{{comment}}» to <2>{{card}}</2>',
      userMovedCardFromListToList: '{{user}} moved <2>{{card}}</2> from {{fromList}} to {{toList}}',
      userMovedThisCardFromListToList: '<0>{{user}}</0><1> moved this card from {{fromList}} to {{toList}}</1>',
      username: 'Username',
      usernameAlreadyInUse: 'Username already in use',
      users: 'Users',
      version: 'Version:',
      viewer: 'Viewer',
      weakPassword: 'Please use stronger password',
      website: 'Website',
      writeComment: 'Write a comment...',
      writeCommentHint: '[Ctrl+Enter] - submit',
    },

    action: {
      addCard_title: 'Add Card',
      addCard: 'Add card',
      addComment: 'Add comment',
      addDescription: 'Add description',
      addList: 'Add list',
      addMember: 'Add member',
      addTask: 'Add task',
      addUser: 'Add user',
      backToProject: 'Back to project',
      cancel: 'Cancel',
      createFile: 'Create file',
      createLabel: 'Create label',
      createNewLabel: 'Create new label',
      delete: 'Delete',
      deleteAttachment: 'Delete attachment',
      deleteAvatar: 'Delete avatar',
      deleteBoard: 'Delete board',
      deleteCard_title: 'Delete Card',
      deleteCard: 'Delete card',
      deleteComment: 'Delete comment',
      deleteImage: 'Delete image',
      deleteLabel: 'Delete label',
      deleteList_title: 'Delete List',
      deleteList: 'Delete list',
      deleteProject_title: 'Delete Project',
      deleteProject: 'Delete project',
      deleteTask_title: 'Delete Task',
      deleteTask: 'Delete task',
      deleteUser: 'Delete user',
      duplicateCard: 'Duplicate Card',
      edit: 'Edit',
      editDescription_title: 'Edit Description',
      editDueDate_title: 'Edit Due Date',
      editEmail_title: 'Edit Email',
      editInformation_title: 'Edit Information',
      editName_title: 'Edit Name',
      editPassword_title: 'Edit Password',
      editPermissions: 'Edit permissions',
      editTimer_title: 'Edit Timer',
      editUsername_title: 'Edit Username',
      hideDetails: 'Hide activity',
      import: 'Import',
      leaveBoard: 'Leave board',
      leaveProject: 'Leave project',
      logOut_title: 'Log Out',
      makeCover_title: 'Make Cover',
      move: 'Move',
      moveCard_title: 'Move Card',
      remove: 'Remove',
      removeBackground: 'Remove background',
      removeCover_title: 'Remove Cover',
      removeFromBoard: 'Remove from board',
      removeFromProject: 'Remove from project',
      removeManager: 'Remove manager',
      removeMember: 'Remove member',
      save: 'Save',
      showAllAttachments: 'Show all attachments ({{hidden}} hidden)',
      showDetails: 'Show activity',
      showFewerAttachments: 'Show fewer attachments',
      start: 'Start',
      stop: 'Stop',
      subscribe: 'Subscribe',
      unsubscribe: 'Unsubscribe',
      uploadNewAvatar: 'Upload new avatar',
      uploadNewImage: 'Upload new image',
    },
  },
};
